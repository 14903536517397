<template>
  <div class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama produk"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1 mt-5" v-if="isLoading == true">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      class="bg-white"
      max-height="80vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{props.column.label}}
          </span>
        </span>

        <span v-else-if="props.column.field == 'formatted_id'">
          <div class="d-flex">
            <span class="mr-1">
              {{props.column.label}}
            </span>
            <b-img
              class="filter-image"
              :class="sortType[0].sortBy == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
              role="button"
              @click="handleSort('id', 'desc')"
            />
          </div>
        </span>
        <span v-else-if="props.column.field == 'name'">
          <div class="d-flex">
            <span class="mr-1">
              {{props.column.label}}
            </span>
            <b-img
              class="filter-image"
              :class="sortType[1].sortBy == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
              role="button"
              @click="handleSort('name', 'desc')"
            />
          </div>
        </span>
        
        <span v-else>
          <span>
            {{props.column.label}}
          </span>
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'standard_price'">
          <span>
            {{ props.row.standard_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'warehouse_names'" id="warehouse_names">
          <div v-if="props.row.warehouse_names">
            <span v-if="props.row.warehouse_names.length <= 3">{{ props.row.warehouse_names.map(e => e).slice(0, 3).join(', ') }}</span>
            <b-dropdown
              v-else
              :text="props.row.warehouse_names.map(e => e).slice(0, 3).join(', ')"
              variant="flat-secondary px-0"
              class="px-0"
            >
              <b-row class="mx-0 pt-1">
                <b-col
                  v-for="(item, index) in props.row.warehouse_names"
                  :key="index"
                  class=""
                >
                  <h6 class="text-dark">{{ item }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </span>

        <span v-else-if="props.column.field === 'item'">
          {{ props.row.item ? props.row.item.name : '-' }}
        </span>

        <span v-else-if="props.column.field === 'avg_purchase_price'">
          <span v-if="checkPermission('harga modal')">
            {{ props.row.avg_purchase_price | formatAmount }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'minimum_price'">
          <span>
            {{ props.row.minimum_price | formatAmount }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="checkPermission('update produk') | checkPermission('delete produk')">
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="goToDetail(props)" v-if="checkPermission('update produk')">
                <span>
                  Edit
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.uuid)" v-if="checkPermission('delete produk')">
                <span>
                  Hapus
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getDataProduct"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BDropdown, BDropdownItem, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BRow,
    BCol,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: 'ID',
          field: 'formatted_id',
        },
        // {
        //   label: 'SKU',
        //   field: 'sku_code',
        // },
        {
          label: 'Nama Produk',
          field: 'name',
        },
        // {
        //   label: 'Nama Alias',
        //   field: 'alias_name',
        // },
        {
          label: 'Lokasi SKU',
          field: 'warehouse_names',
        },
        {
          label: 'Harga Modal',
          field: 'avg_purchase_price',
        },
        {
          label: 'Harga Minimum',
          field: 'minimum_price',
        },
        {
          label: 'Harga Standar',
          field: 'standard_price',
        },
        {
          label: 'Parent Kategori',
          field: 'item',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      formPayload: {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      page: 1,
      uuid: null,
      sortType: [
        {
          type: 'id',
          sortBy: 'desc'
        },
        {
          type: 'name',
          sortBy: 'desc'
        },
      ]
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getDataProduct()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getDataProduct()
  },
  methods: {
    ...mapActions('parentProduct', ['getData']),
    handleSort(type, currentSortOrder, page = this.$route.query.page || 1) {
      // Toggle sort order based on the current sort order
      const sortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';

      // Construct the query params for the API call
      const queryParams = {
        page,
        sort_column: type,
        sort_type: sortOrder
      };

      // Update the component's state for sorting
      this.page = page;
      this.sortType = this.sortType.map(item => {
        if (item.type === type) {
          const newSortOrder = item.sortBy === 'asc' ? 'desc' : 'asc';
          queryParams.sort_type = newSortOrder
          return {
            ...item,
            sortBy: newSortOrder // Update sortBy for the matching type
          };
        }
        return item; // Return the other types unchanged
      });

      // Fetch data with the updated sort options
      this.getData({ params: queryParams, uuid: '' })
        .then(result => {
          this.rows = result.data.data;
          this.isLoading = false;
          this.uuid = null;
        })
        .catch(err => {
          if (err.response?.data?.meta?.messages) {
            errorNotification(this, 'Oops!', err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.error(err);
        });
    },
    goToDetail(props) {
      // console.log(props);
      this.$router.push({ name: 'master-product.detail', params: { id: props.row.uuid }, query: { page: this.page || 1 } })
    },
    getDataProduct(page = this.$route.query.page || 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.page = page
      this.getData({ params: queryParams, uuid: '' })
        .then(result => {
          this.rows = result.data.data
          this.isLoading = false
          this.uuid = null
        }).catch(err => {
          if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    clearItem() {
      this.$store.commit('parentProduct/setEditId', null)
      this.$store.commit('parentProduct/setFormPayload', this.formPayload = {
        product_type: '',
        image: '',
        item_uuid: '',
        specification: '',
        uom_uuid: '',
        alias_name: '',
        sku_code: 0,
        standard_price: 0,
        minimum_price: 0,
        discount_percents: [],
        discount_fixed: 0,
        discount_start_date: '',
        discount_end_date: '',
        warehouses_uuid: [],
      })
    },
    detailItem(item) {
      this.$bvModal.show('modal-customer')
      this.isEdit = false
      this.$store.commit('parentProduct/setResult', item)
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin untuk menghapus produk induk ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('parentProduct/deleteData', `/${uuid}`)
            .then(() => {
              this.getDataProduct()
              this.$swal({
                icon: 'success',
                title: 'Sukses!',
                text: 'Produk induk berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    async editItem(item) {
      await this.clearItem()
      this.uuid = item.uuid
      this.$store.commit('parentProduct/setEditId', item.uuid)
      this.$store.commit('parentProduct/setFormPayload', this.formPayload = {
        product_type: 'parent',
        brand_uuid: item.brand_uuid,
        item_uuid: item.item.uuid,
        specification: item.specification,
        uom_uuid: item.uom.uuid,
        alias_name: item.alias_name,
        sku_code: item.sku_code,
        standard_price: item.standard_price,
        minimum_price: item.minimum_price,
        discount_percents: item.discount_percents.map(o => o),
        discount_fixed: item.discount_fixed,
        discount_start_date: item.discount_start_date,
        discount_end_date: item.discount_end_date,
        // warehouses_uuid: [],
      })
      this.$bvModal.show('modal-add-product-parent')
    },
  },
}
</script>

<style lang="scss">
table {
  &.vgt-table {
    border: none;
  }
  th{
    z-index: 1000;
  }
}

.vgt-responsive {
  height: calc(100vh - 322px) !important;
  background-color: #fff;
}

</style>
