<template>
  <div class="section">
    <header-page>
      <span class="text-dark fw-bold-700 size14">Produk</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          :key="`${item}-${index}`"
          class="filter--button"
          :class="{ 'active' : item == filter }"
          @click="filter = item, comp = (item == 'Produk Induk' ? 'TableProductParent' : 'TableProductEceran' )"
        >
          {{ item }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export">
        <b-button
          v-if="checkPermission('add produk') && comp == 'TableProductParent'"
          variant="outline-secondary py-1 bg-secondary text-white"
          @click="addProduct"
        >
          <span>Tambah Produk Induk</span>
        </b-button>
        <b-button
          v-else-if="checkPermission('add produk') && comp == 'TableProductEceran'"
          variant="outline-secondary py-1 bg-secondary text-white"
          @click="addProductEceran"
        >
          <span>Tambah Produk Eceran</span>
        </b-button>

      </div>
    </div>

    <keep-alive>
      <component :is="comp" />
    </keep-alive>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BDropdown, BDropdownItem, BImg, BPagination, BFormSelect, BModal, VBToggle, BRow, BCol,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'
import TableProductParent from '@/components/MasterProduct/TableProductParent.vue'
import TableProductEceran from '@/components/MasterProduct/TableProductEceran.vue'

export default {
  title() {
    return 'Master Produk'
  },
  components: {
    VueGoodTable,
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TableProductParent,
    TableProductEceran,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      listFilter: ['Produk Induk', 'Produk Eceran'],
      filter: 'Produk Induk',
      comp: 'TableProductParent',
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    if (this.$route.query.q == 'produk-eceran') {
      this.filter = 'Produk Eceran'
      this.comp = 'TableProductEceran'
    }
  },
  methods: {
    async addProduct() {
      await this.$store.dispatch('parentProduct/clearItem')
      this.$store.commit('formWizard/setStep', 1)
      this.$router.push({ name: 'master-product.parent' })
    },
    async addProductEceran() {
      await this.$store.dispatch('parentProduct/clearItem')
      this.$store.commit('formWizard/setStep', 1)
      this.$router.push({ name: 'master-product.retail' })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  height: 60.5vh;
  background-color: #fff;
}
</style>
